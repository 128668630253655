import React, { useRef } from "react";
import "./Join.css";
import emailjs from '@emailjs/browser'
import phoneCall from "../../assets/phoneCall.png"
import whatsapp from "../../assets/whatsapp.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Join = () => {
  const form = useRef()
  const user_email = useRef(null);
  const sendEmail = (e) => {
    e.preventDefault();
    console.log(form);

    emailjs.sendForm('service_1cj7ao7', 'template_w6ni47h', form.current, 'r_ws9MnAHWLbcT8Iy')
      .then((result) => {
        form.current = '';
        toast.success('Success: We will contact you. Thank you.', {
          position: toast.POSITION.TOP_RIGHT
      });
          console.log(result.text);
          
      }, (error) => {
        form.current = '';
          console.log(error.text);
          toast.error('Error: Message not sent !', {
            position: toast.POSITION.TOP_RIGHT
        });
      });

      user_email.current.value = '';
    
  };
  return (
    <div className="Join" id="join-us">
      <ToastContainer />
      <div className="left-j">
        <hr />
        <div>
          <span className="stroke-text">READY TO</span>
          <span>LEVEL UP</span>
        </div>
        <div>
          <span>YOUR BODY</span>
          <span className="stroke-text">WITH US?</span>
        </div>
      </div>

      <div className="right-j">
        <form ref={form} className="email-container" onSubmit={sendEmail}>
          <input ref={user_email} type="email" name="user_email" id="" placeholder="Enter your Email address" />
          <button className="btn btn-j">Join Now</button>
        </form>
        <div className="contact">
          <img src={phoneCall} alt="" />
          <span> 0247430598</span>
        </div>
        <div className="contact">
          <img src={whatsapp} alt="" />
          <span> 0247430598</span>
        </div>
      </div>
       
    </div>
  );
};

export default Join;
