import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image1.png";
import image3 from "../assets/t-image1.png";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I'm putting on weight when I started the diet and the exercise plan FitVixenGH gave me. I can't wait to achieved my ideal body!",
    name: 'BLESS ATAMA',
    status : 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: 'I made the right choice by choosing the FitVixenGH and by choosing the right plan and program I already achieved my ideal body!", ',
    name: 'JOHN KEVIN',
    status: 'COACH'
  },
  {
    image : image3,
    review:' I made the right choice by choosing the FitVixenGH and by choosing the right plan and program I already achieved my ideal body!",',
    name: 'FRANKLIN',
    status: "CUSTOMER"
  }
];
